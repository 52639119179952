import React from 'react';
import Episode from '../Episode';
import { PricingData } from '../../types/PricingData';
import { SeasonData } from '../../types/SeasonData';
import { Region } from '../../types/Region';

interface SeasonProps {
  currentRegion: Region;
  currentSeason: SeasonData;
  allPrices: PricingData[];
}

const Season: React.FC<SeasonProps> = ({ currentRegion, currentSeason, allPrices }) => {
  const pricesForSeason = allPrices.filter(price => price.id === currentSeason.Id)[0]
    .prices;
  const seasonPriceForRegion = pricesForSeason[currentRegion];

  const {Id, Title, Image} = currentSeason;

  return (
    <section key={Id} id={`season-${Id}`}>
      <h3>{Title}</h3>
      <div className="season">
        <div className="season-overview">
          <img src={Image} alt={`Cover for ${Title}`} />
          <div className="pricing">
            <h4>Season price</h4>
            <span className="price">${seasonPriceForRegion}</span>
          </div>
        </div>
        <div className="season-episodes">
          {currentSeason.Episodes.map((currentEpisode) => (
            <Episode currentEpisode={currentEpisode} key={currentEpisode.Id} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Season;
