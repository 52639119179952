import React from 'react';

interface SynopsisProps {
  title: string;
  description: string;
}

const Synopsis: React.FC<SynopsisProps> = ({ title, description }) => {
  return (
    <div className="synopsis">
      <button className="button close-button">&times;</button>
      <h5>{title}</h5>
      <p dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
  );
};


export default Synopsis;
