import React from 'react';
import RegionSelect from '../RegionSelect';
import { Region } from '../../types/Region';

interface PageHeaderProps {
  currentRegion: Region;
  setRegion: (region: Region) => void;
  allRegions: Region[];
}

const PageHeader: React.FC<PageHeaderProps> = ({
  currentRegion,
  setRegion,
  allRegions,
}) => {
  return (
    <header>
      <div className="header-inner">
        <nav>
          <h1>Scriptic</h1>
          <a href="/" className="button">
            Home
          </a>
          <a href="/" className="button active">
            Series
          </a>
          <a href="/" className="button">
            About
          </a>
        </nav>

        <nav>
          <RegionSelect
            currentRegion={currentRegion}
            setRegion={setRegion}
            allRegions={allRegions}
          />
        </nav>
      </div>
    </header>
  );
};

export default PageHeader;
