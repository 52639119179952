import React, { useState } from 'react';
import PageHeader from './components/PageHeader';
import SeasonsList from './components/SeasonsList';

import allSeasonsData from './api/season-data.json';
import allPricesData from './api/season-pricing-data.json';

import { Region } from './types/Region';

const App: React.FC = () => {
  const [currentRegion, setRegion] = useState<Region>(Region.US);

  return (
    <div className="App">
      <PageHeader
        currentRegion={currentRegion}
        setRegion={setRegion}
        allRegions={Object.values(Region)}
      />
      <SeasonsList 
        currentRegion={currentRegion} 
        allSeasons={allSeasonsData} 
        allPrices={allPricesData} 
      />
    </div>
  );
};

export default App;
