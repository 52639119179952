import React, { useRef, useState } from 'react';
import { EpisodeData } from '../../types/EpisodeData';
import Synopsis from '../Synopsis';

interface EpisodeProps {
  currentEpisode: EpisodeData;
}

const Episode: React.FC<EpisodeProps> = ({ currentEpisode }) => {
  const [showSynopsis, setShowSynopsis] = useState(false);
  const episodeRef = useRef<HTMLDivElement>(null);

  const { Image, Title } = currentEpisode;
  const EpisodeSynopsis = currentEpisode.Synopsis;

  const elementClass = `episode${EpisodeSynopsis ? ' clickable' : ''}`;

  const handleEpisodeClick = () => {
    if (!EpisodeSynopsis) return;
    if (episodeRef?.current && !showSynopsis) {
      episodeRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
    setShowSynopsis(prevShowSynopsis => !prevShowSynopsis);
  }

  return (
    <article
      className={elementClass}
      onClick={handleEpisodeClick}
      ref={episodeRef}
    >
      <img src={Image} alt={`Cover for ${Title}`} />
      <h4>{Title}</h4>
      {EpisodeSynopsis && showSynopsis && (
        <Synopsis title={Title} description={EpisodeSynopsis as string} />
      )}
    </article>
  );
};

export default Episode;
