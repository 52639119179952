import React from 'react';
import Season from '../Season';
import { PricingData } from '../../types/PricingData';
import { SeasonData } from '../../types/SeasonData';
import { Region } from '../../types/Region';

interface SeasonsListProps {
  currentRegion: Region;
  allSeasons: SeasonData[];
  allPrices: PricingData[];
}

const SeasonsList: React.FC<SeasonsListProps> = ({
  currentRegion,
  allSeasons,
  allPrices,
}) => {
  return (
    <main>
      {allSeasons.map(currentSeason => (
        <Season
          currentRegion={currentRegion}
          currentSeason={currentSeason}
          allPrices={allPrices}
          key={currentSeason.Id}
        />
      ))}
    </main>
  );
};

export default SeasonsList;
