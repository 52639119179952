import React from 'react';
import { Region } from '../../types/Region';

interface RegionSelectProps {
  currentRegion: Region;
  setRegion: (region: Region) => void;
  allRegions: Region[];
}

const RegionSelect: React.FC<RegionSelectProps> = ({ currentRegion, setRegion, allRegions }) => {
  return (
    <div className="region-select">
      <label htmlFor="region">Region</label>
      <select
        name="region"
        id="region"
        value={currentRegion}
        onChange={e => setRegion(e.target.value as Region)}
      >
        {allRegions.map(region => (
          <option value={region} key={region}>
            {region}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RegionSelect;
